<template>
    <div class="footer">
        @ 2023 江苏贸促国际会展有限公司 备案号：<a
            href="https://beian.miit.gov.cn"
            target="_blank"
            >[苏ICP备05085572号-16]</a
        >
        技术支持：南京禹胜
    </div>
</template>

<script>
export default {};
</script>

<style scoped>
.footer {
    text-align: center;
    padding: 20px 0;
    background: #11a980;
    color: #fff;
}
a {
    color: #fff;
    text-decoration: none;
}
</style>