import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import {gm} from '@/utils/gm'

import VueAwesomeSwiper from 'vue-awesome-swiper'
import "swiper/dist/css/swiper.css";
Vue.use(VueAwesomeSwiper);

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import Vant from 'vant';
import 'vant/lib/index.css';

import VueLazyload from 'vue-lazyload'

// Vue.use(VueLazyload)

// const loadimage = require('./assets/loading.gif')
// const errorimage = require('./assets/error.gif')

Vue.use(VueLazyload, {
  preLoad: 1.3,
  // error: errorimage,
  // loading: loadimage,
  attempt: 1
})

Vue.use(Vant);

Vue.use(ElementUI);

Vue.config.productionTip = false

Vue.prototype.$baseUrl = 'https://djy.0l0l.cn/v'
Vue.prototype.$gm = gm

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
