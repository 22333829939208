<template>
    <div id="app" :style="setAppWidth">
        <router-view />
    </div>
</template>

<script>
export default {
    data() {
        return {
            styleStr: "",
        };
    },
    computed: {
        setAppWidth() {
            if (this.$store.state.isMobileStyle) {
              return 'min-width:auto'
            }
        },
    },
    created() {
        console.log(process.env.NODE_ENV);
        // production development
        let i18nlang = sessionStorage.getItem("i18n");
        if (i18nlang == "en" || i18nlang == "zh") {
            this.$i18n.locale = i18nlang;
        }
    },
    mounted() {
        // if (this.isMobile()) {
        //     this.styleStr = "min-width:auto";
        // }
    },
    methods: {
        isMobile() {
            let flag = navigator.userAgent.match(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            );
            return flag;
        },
    },
};
</script>

<style lang="less">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    min-width: 1600px;
    // max-width: 1800px;
    margin: 0 auto;
}
* {
    padding: 0;
    margin: 0;
}
.h5-title {
    .title {
        font-weight: bold;
        text-align: center;
        color: #585858;
    }
    .subtitle {
        font-size: 12px;
        text-align: center;
        padding-top: 5px;
        color: #585858;
    }
}
</style>
