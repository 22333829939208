export default {
    nav: {
        index: "Home",
        presentation: "Exhibition",
        service: "Exhibitor Services",
        viewers: "Visitor Service",
        news: "Exhibition News",
        contact: "Contact Us"
    },
    common: {
        plh:"Please enter",
        slp:"Please select",
        exhibition: "Exhibition Overview",
        scope: "Scope of display",
        themesev: "7 themes.",
        ciogl: "Comprehensive interpretation of green low-carbon",
        highlights: "Exhibition Highlights",
        highlightsText: "Model XX, covering xxx areas",
        learnmore: "Learn more",
        highlightsEvents: "Highlights",
        atlas: "Show Brochure",
        exhibiting: "Exhibiting Private Enterprises",
        onsiteMedia: "On-site media",
        indexTitle: "2023 Jiangsu Green Low Carbon Building International Expo to be held in Nanjing in October",
        indexSubTitle: `With the theme of "Greening Urban and Rural Areas, Towards a Lower Carbon Future", the Expo is co-organized by the Jiangsu Provincial Department of Housing and Urban-Rural Development and the Jiangsu Sub-Council of the China Council for the Promotion of International Trade (CCPIT). According to Mr. Lu Hongwei, Chief Engineer of Jiangsu Provincial Department of Housing and Urban-Rural Development, the expo will focus on the latest achievements in green and low-carbon developme`,
        checkDetail: "View Details",
        zhanhuiMsg: "Exhibition News",
        lobbies: "supply and procurement hall",
        lobbiesTitle: "One-click matchmaking between supply and demand",
        lobbiesSubTitle: "Submit Purchase Intention -> Suggest Products -> Exhibitor Response",
        mybuyer: "I'm a buyer.",
        publishing: "Posting Requests",
        myExhibitor: "I'm an exhibitor",
        finding: "Opportunities",
        contactUs: "Contact Us",
        contactUs2: "contact details",
        contactUsSub: "You can get in touch with us through the following ways, welcome your call!",
        telephones: "telephones",
        mail: "邮email箱",
        address: "address",
        addressText: "No.300, Jiangdong Middle Road, Jianye District, Nanjing, China",
        publicNumber: "public number",
        publicNumberText: "Scan the QR code to learn more about us",
        outlogin:'Log out'
    },
    people_server_map_address: {
        line_1: '1. Subway',
        line_2: 'Take Subway Line 2 or Line 10 to Yuantong Station. It is a 5-minute walk from Exit 4 to Pavilions 4 to 7.',
        line_3: 'Nanjing South Railway Station: South  take metro line 1, line (Wallace gao bridge direction), at the ender door stop, transfer to the subway line 10 (in the direction of Olympic sports center), in yuan tong station (4 mouth turn right, walk 190 meters east road along the river, turn right go straight into the bailong river west street, 280 meters to reach), walk to nanjing international expo center) to subway line no. 3 (in the direction of forest farm), Arrive at Dafingong Station, transfer to Metro Line 2 (to Youfang Bridge), get off at Yuantong Station (turn right at Exit 4, walk 180 meters along Jiangdong Middle Road, turn right into Bailongjiang West Street and walk 280 meters), then walk to Nanjing International Expo Center',
        line_4: 'Nanjing Station: Take subway Line 1 (toward China Medical University), arrive at Xinjiekou Station, transfer to subway Line 2 (toward Youfang Bridge), get off at Yuantong Station (turn right at Exit 4, walk 180 meters along Jiangdong Middle Road, turn right into Bailongjiang West Street and walk 280 meters), and walk to Nanjing International Expo Center',

        line_5: '2. Public Transportation',
        line_6: '1, Jiangdong Middle Road Bailongjiang East Street - Bus stop via bus: 102, 109',
        line_7: '2, Jiangdong Middle Road Jinling Conference Center - Bus stop Bus: b2',
        line_8: '3, Jiangdong Middle Road Expo Center East gate - Bus stop Bus: 102, 109, b2',
        line_9: '4, Yanshan Road Bailongjiang West Street - Bus stop via bus: 102, 134',
        line_10: '5, Jiangdong Middle Road Yuantong South - bus stop via bus: 102, 109, b2',

        line_11: '3. Tram',
        line_12: 'Tram Line 1 Expo Center East Gate/Yuantong Station off',

        line_13: '4. Self-drive',
        line_14: '(I) Arriving at the Exhibition Hall:',
        line_15: '1. Shanghai direction is Shanghai-Nanjing Expressway - Ring Expressway - Exit 306B - Jiangshan Street - Jiangdong Middle Road/Yanshan Road',
        line_16: "2. The direction of Ma 'anshan and Wuhu is Ningma Expressway - Ring Expressway -306B exit - Jiangshan Dajie - Jiangdong Middle Road/Yanshan Road. The direction of Hefei is Ninghe Expressway - Yangtze River Third Bridge - Ring Expressway -306B exit - Jiangshan Dajie - Jiangdong Middle Road/Yanshan Road",
        line_17: '3. Yangzhou direction is Ningtong Expressway - Second Yangtze River Bridge - Ring Expressway -306B exit Jiangshan Street - Jiangdong Middle Road/Yanshan Road',
        line_18: '4. The direction of Hangzhou is Ninghang Expressway - Airport Expressway - Ring Expressway -306B exit - Jiangshan Street - Jiangdong Middle Road/Yanshan Road',
        line_19: '(2) Underground Parking lot:',
        line_20: '1. Yanshan Road - Enter the Exhibition Hall at Gate 12 below the Expo Center - Park at the P1 parking lot according to the instructions on site; The P1 car park provides 1200 parking Spaces and 25 bus Spaces. It is close to Pavilions 1 and 2 and the 3A entry Hall.',
        line_21: '2. Enter the exhibition hall at Gate 7 of Jiangdong Middle Road -- Go to P2 parking lot according to site instructions (open according to exhibition demand); Parking lot P2, which provides 2,200 parking Spaces, is near Pavilions 4-9, entrance Hall 6 and One Restaurant (Hall 10).',
    },
    about: {
        line_1: `On July 10, the Jiangsu Provincial Department of Housing and Urban-Rural Development announced in Nanjing that the "2023 Jiangsu International Expo on Green and Low-Carbon Buildings" will be held from October 10 to 11, 2023 at the Nanjing International Expo Center.`,
        line_2: `It is reported that the Expo to "green urban and rural areas, towards a lower carbon future" as the theme, by the Jiangsu Provincial Department of Housing and Urban-Rural Development, the China Council for the Promotion of International Trade, Jiangsu Province Branch co-sponsored. Expo for two days, will focus on the display of domestic and foreign green low-carbon buildings, urban and rural green low-carbon development practices, intelligent construction and new building industrialization, urban renewal, livable and beautiful countryside and other areas of the latest technological achievements, to create housing and urban-rural construction of green and low-carbon exchange platforms, and smooth the suppliers and buyers, the domestic market and the international market trading channels.`
    },
    peopleServer: {
        tab1:"Visitor Guide",
        tab2:"Visit Registration",
        tab3:"Traffic map",
        tab4:"Accommodation",
        tab5:"Supply Accommodation",
        username: "name and surname",
        flat: "work unit",
        phone: "telephones",
        datevisit: "Date of visit",
        selectDate: "Select Date",
        verification: "verification code",
        submit: "Submit",
        count: "element",
        plh: "Please enter content",
        submitText: "Submit Message",
        plhs:{
            username:"Please enter your name",
            flat:"Please enter the flat",
            phone:"Please enter your phone number",
            datevisit:"Please select the date of your visit",
            verification:"Please enter the verification code",
            content:"Please enter content"
        },
        map:{
            title:"Nanjing International Expo Center",
            subtitle:"Address: No. 300 Jiangdong Middle Road, Jianye District, Nanjing, Jiangsu Province, China"
        }
    },
    tabs:{
        exhibition: "Exhibition Overview",
        exhibitionSchedule:"Exhibitions & Events",
        previousReview:"Previous Review",
        download:"Download",
        formbioa:"Exhibitor Application Form"
    },
    shopServer:{
        tab1:"Exhibiting Process",
        tab2:"Booth Price",
        tab3:"Booth diagram",
        tab4:"Building",
        tab5:"Transportation",
        tab6:"Advertising",
        tab7:"On-site rental",
        tab8:"Accommodation",
        tab9:"Exhibitor Manual",
    },
    news:{
        tab1:"Exhibition Style",
        tab2:"Demonstration garden style",
    },
    login:{
        homeTitle:"Login/Register",
        l_title:"Welcome to log in",
        l_subtitle:"Jiangsu Green Low Carbon Building International Expo Platform",
        tab1:"account login",
        tab2:"Mobile Verification Code Login",
        form1:"username",
        form2:"password",
        form3:"verification ",
        form4:"I have read and agree to",
        form5:"user manual",
        form6:"phone number",
        submit:"log in",
        form7:"Register for an account",
        form8:"forgotten password?",

        form9:"Please read and agree to the above agreement in full",
        form10:"Please read and agree to the User Agreement in its entirety",
        plh1:"Please enter your account number",
        plh2:"Please enter your password",
        plh3:"Please enter the verification code",
        plh4:"Please enter your cell phone number",
        ret:"Back",
        form11:"Login Successful"
    },
    reg:{
        title:"Register for an account",
        form1:"CAPTCHA",
        form2:"email",
        form3:"Confirm Password",
        form4:"registrations",
        plh1:"Please enter your e-mail address",
        plh2:"Please enter your password",
        plh3:"Please enter the verification code",
        plh4:"Please enter your cell phone number",
        plh5:"Please enter your password again",
        plh6:"Please enter the correct ID number",
        sex:"genders",
        idCard:"I.D. number",
        form5:"real name",
        form6:"man",
        form7:"women",
        realName:"real name",
        form8:"Inconsistent passwords entered twice",
        form9:"Successful registration",
    },
    show_server_swiper: {
        swiper_text_1: 'Hanting Hotel (Nanjing Hexi International Expo Center Store)',
        swiper_text_2: 'International Youth Conference Hotel',
        swiper_text_3: 'Golden Overmont Hotel, Nanjing',
        swiper_text_4: 'Xinhua Media Hotel, Nanjing',
        swiper_text_5: 'Renaissance Huatai Hotel Nanjing',
        swiper_text_6: 'Nanjing Olympic Sports Center Yaduo S Hotel',
        swiper_text_7: 'Mercure Hotel Olympic Sports Center',
        swiper_text_8: 'Jewel Holiday Hotel (Nanjing Olympic Sports Center Store)',
        swiper_text_9: 'Nanjing International Expo Center Hotel',
        swiper_text_10: 'Yishang Hotel (Nanjing Expo Center Olympic Sports East Subway Station Store)',
        swiper_text_11: 'Season Hotel (Hexi Expo Center, Nanjing)',
        swiper_text_12: 'Jewel Holiday Inn Fung Tainan Road branch',
    },
    swiper2:{
        line1:"Technology Matching, Industry Docking, Talent Exchange",
        line1_1:"Comprehensive display of new results and achievements",
        line2:"More than 50 concurrent events",
        line2_1:"Thematic negotiation policy propaganda talent exchange full coverage",
        line3:"12,000 square meters of exhibition space",
        line3_1:"400 standard booths",
        line4:"Industry authorities, industry link companies, financial institutions,",
        line4_1:"Pilot demonstration units, industry associations, research institutes, universities, etc.",
        line5:"50 media, more than 80 journalists",
        line5_1:"Expanding the visibility and influence of the exhibition in all aspects",
    }
}