<template>
    <div class="home">
        <Header></Header>
        <div class="main">
            <div class="banner">
                <div class="info">
                    <swiper
                        :options="swiperOption"
                        ref="mySwiper"
                        class="swiper-no-swiping"
                    >
                        <swiper-slide>
                            <img :src="$gm('1-1.png')" alt="" />
                            <!-- <img src="@/assets/image/1-1.png" alt="" /> -->
                        </swiper-slide>
                        <swiper-slide>
                            <img :src="$gm('1-1-2.png')" alt="" />
                            <!-- <img src="@/assets/image/1-1-2.png" alt="" /> -->
                        </swiper-slide>
                        <swiper-slide>
                            <img :src="$gm('1-1-3.png')" alt="" />
                            <!-- <img src="@/assets/image/1-1-3.png" alt="" /> -->
                        </swiper-slide>
                        <!-- <swiper-slide>
                            <img src="@/assets/image/1-1-4.png" alt="" />
                        </swiper-slide> -->
                    </swiper>
                </div>
            </div>
            <div class="page-item">
                <div class="info">
                    <div class="page-title">
                        <div class="page-title_info">
                            <div class="page-title_up">展会简介</div>
                            <div class="page-title_line"></div>
                            <div class="page-title_sub">EXPO PROFILE</div>
                        </div>
                    </div>

                    <div class="expo">
                        <div class="expo-info">
                            <div class="left">
                                <div class="video">
                                    <!-- <video src="" controls></video> -->
                                    <img
                                    :src="$gm('2-1-3.png')"
                                    />
                                </div>
                            </div>
                            <div class="right">
                                <div class="text">
                                    为深化与有关国家（地区）、国际组织在城乡建设绿色低碳发展方面的交流合作，发挥企业在建设科技创新中的主体作用，丰富完善建设科技创新成果推广体系，推动相关产业向高端化、智能化、绿色化、融合化发展，今年3月以来，省住房城乡建设厅结合主题教育，主动对接省有关单位，深入企业开展座谈交流，谋划进一步推动住房城乡建设领域科技创新，强化企业科技创新主体地位，贯通创新端、转化端、推广端、应用端的务实举措。通过调研，了解到社会各界对完善建设科技成果推广体系需求强烈，期待省厅搭建综合性的展示交易平台，拓展创新成果应用场景，深化国际交流合作。
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-item">
                <div class="page-title">
                    <div class="page-title_info">
                        <div class="page-title_up">展会新闻</div>
                        <div class="page-title_line"></div>
                        <div class="page-title_sub">EXPO NEWS</div>
                    </div>
                </div>
                <div class="news">
                    <div class="info">
                        <!-- <div class="item" v-for="(v, i) in navList" :key="i">
                            <div class="item-info">
                                <div class="timer">{{ v.timer }}</div>
                                <div class="title">
                                    {{ v.title }}
                                </div>
                                <div class="subtitle">
                                    <span>{{ v.subtitle }}</span>
                                </div>
                                <img :src="v.img" alt="" />
                            </div>
                        </div> -->
                        <swiper
                            :options="swiperOptions"
                            ref="mySwiper"
                            class="swiper-no-swiping"
                        >
                            <swiper-slide v-for="(v, i) in navList" :key="i">
                                <div class="item">
                                    <div class="item-info" @click="linkUrl(v)">
                                        <div class="timer">{{ v.timer }}</div>
                                        <div class="title">
                                            {{ v.title }}
                                        </div>
                                        <div class="subtitle">
                                            <span>{{ v.subtitle }}</span>
                                        </div>
                                        <img :src="v.img" alt="" />
                                    </div>
                                </div>
                            </swiper-slide>
                        </swiper>
                    </div>
                </div>
            </div>
            <div class="page-item">
                <div class="page-title">
                    <div class="page-title_info">
                        <div class="page-title_up">展商推荐</div>
                        <div class="page-title_line"></div>
                        <div class="page-title_sub">EXPO RECOMMENDED</div>
                        <div class="page-title_subs" style="font-size: 12px">
                            （展商顺序随机排列）
                        </div>
                    </div>
                </div>
                <div class="reco">
                    <div class="info">
                        <!-- <div
                            class="item"
                            v-for="(v, i) in shopLogoList"
                            :key="i"
                        >
                            <img :src="v.img" alt="" />
                        </div> -->
                        <swiper
                            :options="swiperOptionss"
                            ref="mySwiper"
                            class="swiper-no-swiping"
                        >
                            <swiper-slide
                                v-for="(v, i) in shopLogoList"
                                :key="i"
                            >
                                <div class="item">
                                    <img :src="v.img" alt="" />
                                </div>
                            </swiper-slide>
                        </swiper>
                        <swiper
                            :options="swiperOptionss2"
                            ref="mySwiper"
                            class="swiper-no-swiping"
                        >
                            <swiper-slide
                                v-for="(v, i) in shopLogoList"
                                :key="i"
                            >
                                <div class="item">
                                    <img :src="v.img" alt="" />
                                </div>
                            </swiper-slide>
                        </swiper>
                    </div>
                    <!-- <p class="tips">展商顺序随机排列</p> -->
                </div>
            </div>
            <div class="page-item">
                <div class="page-title">
                    <div class="page-title_info">
                        <div class="page-title_up">供采大厅</div>
                        <div class="page-title_line"></div>
                        <div class="page-title_sub">SOURCING HALL</div>
                    </div>
                </div>
                <div class="hall">
                    <div class="info">
                        <div class="left">
                            <div class="sign">
                                <div class="sign-info">
                                    <div class="mark">
                                        <div class="title">
                                            {{ $t("common.mybuyer") }}
                                        </div>
                                        <div class="btn" @click="register(2)">
                                            <span>{{
                                                $t("common.publishing") 
                                            }}</span>
                                            <img
                                                :src="$gm('right.png')"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="right">
                            <div class="sign">
                                <div class="sign-info">
                                    <div class="mark">
                                        <div class="title">
                                            {{ $t("common.myExhibitor") }}
                                        </div>
                                        <div class="btn" @click="register(1)">
                                            <span>{{
                                                $t("common.finding")
                                            }}</span>
                                             <img
                                                :src="$gm('right.png')"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="link">
                <div class="info">
                    <div class="qr">
                        <div>
                            <img  :src="$gm('gzhqr.jpg')"  alt="" />
                            <div class="title">微信扫码关注我们</div>
                        </div>
                    </div>
                    <!-- <div class="yq">
                        <div class="title">友情链接</div>
                        <div class="list">
                            <div class="item">
                                <a
                                    href="http://jsszfhcxjst.jiangsu.gov.cn/"
                                    target="_blank"
                                >
                                    江苏省住房和城乡建设厅
                                </a>
                            </div>
                            <div class="item">
                                <a
                                    href="https://gbservice.cn/#/index"
                                    target="_blank"
                                >
                                    绿建服务网
                                </a>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="contus">
                        <div class="title">友情链接</div>
                        <p>
                            <span>江苏省住房和城乡建设厅科技发展中心</span>
                            <br />
                            <span>陈潇阳：15850576018</span>
                        </p>
                        <p>
                            <span>江苏贸促国际会展有限公司</span>
                            <br />
                            <span>陈潇阳：15996300128</span>
                        </p>
                    </div> -->
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
import { firmsList,homeNewList } from "@/utils/map";
export default {
    components: {
        Header,
        Footer,
    },

    data() {
        return {
            swiperOptionss2:{
                loop: true, //开启循环模式
                slidesPerView: 10, //显示个数
                spaceBetween: 0, //元素之间间隔距离
                speed: 3000, //slider自动滑动开始到结束的时间
                freeMode: true, // 设置了是否可以自由运动
                noSwiping: true,
               
                autoplay: {
                    reverseDirection:true,
                    //开启自动切换
                    delay: 0, //自动切换的时间间隔
                    disableOnInteraction: true,
                },
            },
            swiperOptionss: {
                loop: true, //开启循环模式
                slidesPerView: 10, //显示个数
                spaceBetween: 0, //元素之间间隔距离
                speed: 3000, //slider自动滑动开始到结束的时间
                freeMode: true, // 设置了是否可以自由运动
                noSwiping: true,
                autoplay: {
                    //开启自动切换
                    delay: 0, //自动切换的时间间隔
                    disableOnInteraction: true,
                },
            },
            swiperOptions: {
                loop: true, //开启循环模式
                slidesPerView: 3, //显示个数
                spaceBetween: 20, //元素之间间隔距离
                speed: 10000, //slider自动滑动开始到结束的时间
                freeMode: true, // 设置了是否可以自由运动
                noSwiping: true,
                autoplay: {
                    //开启自动切换
                    delay: 0, //自动切换的时间间隔
                    disableOnInteraction: true,
                },
            },
            navList: [
                ...homeNewList
            
            ],
            shopLogoList: [
                // ...firmsList,
                // { img: require("@/assets/image/shoplogo/1.jpg") },
                // { img: require("@/assets/image/shoplogo/2.jpg") },
                // { img: require("@/assets/image/shoplogo/3.jpg") },
                // { img: require("@/assets/image/shoplogo/4.jpg") },
                // { img: require("@/assets/image/shoplogo/5.jpg") },
                // { img: require("@/assets/image/shoplogo/6.png") },
                // { img: require("@/assets/image/shoplogo/7.jpg") },
                // { img: require("@/assets/image/shoplogo/8.png") },
            ],
            swiperOption: {
                slidesPerView: 1,
                spaceBetween: 0,
                speed: 1000,
                autoHeight: true,
                autoplay: true,
                loop: true,
                height: window.innerHeight - 74,
                // height:'calc(100vh - 74px)',
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
            },
        };
    },
    created(){
        this.shopLogoList = firmsList.filter(v=>v.img)
      
        let clone = JSON.parse(JSON.stringify(this.navList))
        this.navList = [ ...clone.reverse() ]
    },
    methods: {
        register(type) {
            this.$router.push("/register?type=" + type);
        },
        linkUrl(v){
            if(v.link){
                window.open(v.link)
            }
        }
    },
};
</script>

<style scoped lang="less">
.main {
    padding-bottom: 50px;
    
}
.page-item {
    padding-top: 80px;
}
.page-title {
    user-select: none;
    .page-title_info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .page-title_line {
        width: 400px;
        height: 1px;
        background: #636261;
        margin: 16px 0;
    }
    .page-title_up {
        letter-spacing: 1.2em;
        font-size: 24px;
        text-indent: 1.2em;
    }
    .page-title_sub {
        font-size: 18px;
    }
}
@keyframes move {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.04);
    }
    100% {
        transform: scale(1);
    }
}
.banner {
    overflow: hidden;
    .info {
        animation-name: move;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-duration: 5s;
        img {
            width: 100%;
        }
    }
}
.expo {
    padding: 0 80px;
    padding-top: 80px;
    .expo-info {
        display: flex;
        padding: 10px;
        box-shadow: 0 0 3px 0px #ccc;
        .left {
            flex: 1;
        }
        .right {
            flex: 1;
            padding: 50px;
            .text {
                text-align: justify;
                font-size: 20px;
                color: #5e5e5e;
                line-height: 40px;
                text-indent: 2em;
            }
        }
        .video {
            height: 100%;
            video {
                height: 100%;
                width: 100%;
            }
        }
    }
}
.news {
    .info {
        padding: 80px;
        padding-bottom: 0;
        display: flex;
        justify-content: center;
        .item {
            // width: 25%;
            box-sizing: border-box;
            padding: 20px;
            border: 1px solid #fff;
            cursor: pointer;
            height: 100%;
        }
        .item:hover {
            // border: 1px solid #009f72;
        }
        .item-info {
            height: 100%;
            display: flex;
            flex-direction: column;
            .timer {
                color: #5e5e5e;
                font-size: 26px;
            }

            .title {
                font-size: 20px;
                padding: 20px 0 16px 0;
                font-weight: bold;
            }

            .subtitle {
                color: #5e5e5e;
                line-height: 23px;
                padding-bottom: 10px;

                span {
                    word-break: break-all;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                }
            }
            img {
                margin-top: auto;
                width: 100%;
            }
        }
    }
}
.reco {
    .info {
        padding: 80px 80px 0 80px;
        display: flex;
        flex-wrap: wrap;
        .item {
            flex-basis: calc(100% / 6);
            display: grid;
            place-items: center;
            padding: 20px;
            box-sizing: border-box;
            box-shadow: inset 0 0 20px 0px #cccccc7a;
            height: 100%;
            img {
                width: 100%;
            }
        }
    }
}
.hall {
    .info {
        padding: 80px 80px 0 80px;
        display: flex;
        & > div {
            flex: 1;
        }
        .left {
            margin-right: 40px;
        }
        .sign {
            height: 0;
            padding-bottom: 50%; // 这个就是高的比例
            position: relative;
        }
        .sign-info {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            display: grid;
            place-items: center;
            background: url(@/assets/image/1-5.png);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            box-shadow: 0 0 20px 5px #ccc;
            .mark {
                .title {
                    color: #fff;
                    font-size: 38px;
                    text-align: center;
                }
                .btn {
                    background: #fff;
                    display: flex;
                    align-items: center;
                    padding: 5px 20px;
                    margin-top: 30px;
                    cursor: pointer;
                    user-select: none;
                    img {
                        width: 50px;
                        margin-left: auto;
                    }
                    span {
                        font-size: 36px;
                        margin-right: 10px;
                    }
                }
            }
        }
        .right .sign-info {
            background: url(@/assets/image/1-6.png);
        }
    }
}

.link {
    border-top: 5px solid #11a980;
    margin-top: 80px;
    .info {
        padding: 80px 80px 0 80px;
        display: flex;

        & > div {
            flex: 1;
        }
        .qr {
            text-align: center;
            img {
                width: 250px;
            }
            .title {
                text-align: center;
                font-weight: bold;
            }
            & > div {
                display: inline-block;
            }
        }
        .yq {
            text-align: center;
            .title {
                font-weight: bold;
                font-size: 24px;
                padding-bottom: 30px;
            }
            .item {
                padding: 10px;
                font-weight: bold;
                a {
                    color: #000;
                    text-decoration: none;
                }
            }
        }
        .contus {
            .title {
                font-weight: bold;
                font-size: 24px;
                padding-bottom: 30px;
            }
            p {
                margin-bottom: 30px;
                line-height: 30px;
            }
        }
    }
}
.footer {
    text-align: center;
    padding: 20px 0;
    background: #11a980;
    color: #fff;
}
.tips {
    text-align: center;
    padding-top: 30px;
    font-weight: bold;
}
</style>