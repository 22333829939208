import i18n from '@/i18n'
import { gm } from './gm'

const jsjky = () => import('@/components/firms/jsjky.vue')
const jhhx = () => import('@/components/firms/jhhx.vue')
const dfyh = () => import('@/components/firms/dfyh.vue')
const jsqt = () => import('@/components/firms/jsqt.vue')
const shsk = () => import('@/components/firms/shsk.vue')
const hdjs = () => import('@/components/firms/hdjs.vue')
const yajs = () => import('@/components/firms/yajs.vue')
const jgs = () => import('@/components/firms/jgs.vue')
const zyf = () => import('@/components/firms/zyf.vue')
const yjjt = () => import('@/components/firms/yjjt.vue')
const jsjs = () => import('@/components/firms/jsjs.vue')
const btkj = () => import('@/components/firms/btkj.vue')
const kld = () => import('@/components/firms/kld.vue')
const jlkj = () => import('@/components/firms/jlkj.vue')
export const groupMap = {
    f0: jsjky, // 江苏建科院 1
    f1: jhhx, //江海杭萧 1
    f2: dfyh, //东方雨虹1
    f3: jsqt,    //江苏擎天工业互联网有限公司简介 1
    f4: shsk, //上海圣奎 1
    f5: hdjs, //宏大建设 1
    f6: yajs,// 江苏宜安建设 1
    f7: jgs, // 佳固士 1
    f8: zyf, // 中亿丰 1
    f9: yjjt, // 江苏扬建 1
    f10: jsjs, // 江苏锦上 1
    f11: btkj, // 南京贝唐　１
    f12: jlkj, // 久瓴科技
    f13: kld, // 久瓴科技
    f14: () => import('@/components/firms/26du/index.vue'),
    f15: () => import('@/components/firms/gdw/index.vue'),
    f16: () => import('@/components/firms/gwjs/index.vue'),
    f17: () => import('@/components/firms/jsjzzyxy/index.vue'),
    f18: () => import('@/components/firms/jslx/index.vue'),
    f19: () => import('@/components/firms/jpyl/index.vue'),
    f20: () => import('@/components/firms/elematic/index.vue'),
    f21: () => import('@/components/firms/dv/index.vue'),
    f22: () => import('@/components/firms/bjaq/index.vue'),
    f23: () => import('@/components/firms/hbok/index.vue'),
    f24: () => import('@/components/firms/kdgj/index.vue'),
    f25: () => import('@/components/firms/kldtl/index.vue'),
    f26: () => import('@/components/firms/lczg/index.vue'),
    f27: () => import('@/components/firms/kbd/index.vue'),
    f28: () => import('@/components/firms/meb/index.vue'),
    f29: () => import('@/components/firms/bhbl/index.vue'),
    f30: () => import('@/components/firms/cztj/index.vue'),
    f31: () => import('@/components/firms/dlly/index.vue'),
    f32: () => import('@/components/firms/jssw/index.vue'),
    f33: () => import('@/components/firms/jywy/index.vue'),
    f34: () => import('@/components/firms/jykj/index.vue'),
    f35: () => import('@/components/firms/jbh/index.vue'),
    f36: () => import('@/components/firms/jlz/index.vue'),
    f37: () => import('@/components/firms/klsk/index.vue'),
    f38: () => import('@/components/firms/nthy/index.vue'),
    f39: () => import('@/components/firms/wxlr/index.vue'),
    f40: () => import('@/components/firms/wxxzh/index.vue'),
    f41: () => import('@/components/firms/nths/index.vue'),
    f42: () => import('@/components/firms/jssx/index.vue'),
    f43: () => import('@/components/firms/hxnj/index.vue'),
    f44: () => import('@/components/firms/jsjr/index.vue'),
    f45: () => import('@/components/firms/sjzxy/index.vue'),
    f46: () => import('@/components/firms/sghjt/index.vue'),
    f47: () => import('@/components/firms/dgsp/index.vue'),
    f48: () => import('@/components/firms/szjz/index.vue'),
    f49: () => import('@/components/firms/szxy/index.vue'),
    f50: () => import('@/components/firms/szylsj/index.vue'),
    f51: () => import('@/components/firms/tsbq/index.vue'),
    f52: () => import('@/components/firms/z52/index.vue'),
    f53: () => import('@/components/firms/z53/index.vue'),
    f54: () => import('@/components/firms/z54/index.vue'),
    f55: () => import('@/components/firms/z55/index.vue'),
    f56: () => import('@/components/firms/z56/index.vue'),
    f57: () => import('@/components/firms/z57/index.vue'),
    f58: () => import('@/components/firms/z58/index.vue'),
    f59: () => import('@/components/firms/z59/index.vue'),
    f60: () => import('@/components/firms/z60/index.vue'),
    f61: () => import('@/components/firms/z61/index.vue'),
    f62: () => import('@/components/firms/z62/index.vue'),
    f63: () => import('@/components/firms/z63/index.vue'),
    f64: () => import('@/components/firms/z64/index.vue'),
    f65: () => import('@/components/firms/z65/index.vue'),
    f66: () => import('@/components/firms/z66/index.vue'),
    f67: () => import('@/components/firms/z67/index.vue'),
    f68: () => import('@/components/firms/z68/index.vue'),
    f69: () => import('@/components/firms/z69/index.vue'),
    f70: () => import('@/components/firms/z70/index.vue'),
    f71: () => import('@/components/firms/z71/index.vue'),
    f72: () => import('@/components/firms/z72/index.vue'),
    f73: () => import('@/components/firms/z73/index.vue'),
    f74: () => import('@/components/firms/z74/index.vue'),
    f75: () => import('@/components/firms/z75/index.vue'),
    f76: () => import('@/components/firms/z76/index.vue'),
    f77: () => import('@/components/firms/z77/index.vue'),
    f78: () => import('@/components/firms/z78/index.vue'),
    f79: () => import('@/components/firms/z79/index.vue'),
    f80: () => import('@/components/firms/z80/index.vue'),
    f81: () => import('@/components/firms/z81/index.vue'),
    f82: () => import('@/components/firms/z82/index.vue'),
    f83: () => import('@/components/firms/z83/index.vue'),
    f84: () => import('@/components/firms/z84/index.vue'),
    f85: () => import('@/components/firms/z85/index.vue'),
    f86: () => import('@/components/firms/z86/index.vue'),
    f87: () => import('@/components/firms/z87/index.vue'),
    f88: () => import('@/components/firms/z88/index.vue'),
}

export const firmsList = [
    {
        img: gm("shoplogo/3.png"),
        name: "江苏省建筑科学研究院有限公司",
        active: "f0",
    },
    {
        img: gm("shoplogo/1.png"),
        name: "江苏江海杭萧绿色建筑科技有限公司",
        active: "f1",
    },
    {
        img: gm("shoplogo/2.png"),
        name: "江苏宜安建设有限公司",
        active: "f6",
    },
    {
        img: gm("shoplogo/4.png"),
        name: "宏大建设集团有限公司",
        active: "f5",
    },
    {
        img: gm("shoplogo/5.png"),
        name: "南京贝唐建筑科技有限公司",
        active: "f11",
    },
    {
        img: gm("shoplogo/6.png"),
        name: "苏州佳固士新材料科技有限公司",
        active: "f7",
    },
    {
        img: gm("shoplogo/7.png"),
        name: "北京东方雨虹防水技术股份有限公司",
        active: "f2",
    },

    {
        img: gm("shoplogo/9.png"),
        name: "江苏擎天工业互联网有限公司",
        active: "f3",
    },

    {
        img: gm("shoplogo/10.png"),
        name: "上海圣奎塑业有限公司",
        active: "f4",
    },
    {
        img: gm("shoplogo/11.png"),
        name: "中亿丰建设集团股份有限公司",
        active: "f8",
    },
    {
        img: gm("shoplogo/12.png"),
        name: "江苏扬建集团有限公司",
        active: "f9",
    },
    {
        img: gm("shoplogo/13.jpg"),
        name: "江苏锦上建筑科技有限公司",
        active: "f10",
    },
    {
        img: gm("shoplogo/14.png"),
        name: "苏州柯利达装饰股份有限公司",
        active: "f13",
    },
    {
        img: gm("shoplogo/15.png"),
        name: "久瓴科技",
        active: "f12",
    },
    {
        img: gm("shoplogo/16.png"),
        name: "江苏二十六度机器人研究院有限公司",
        active: "f14",
    },
    {
        img: gm("shoplogo/17.png"),
        name: "固德威技术股份有限公司",
        active: "f15",
    },
    {
        img: gm("shoplogo/18.png"),
        name: "国网江苏",
        active: "f16",
    },
    {
        img: gm("shoplogo/19.png"),
        name: "江苏建筑职业学院",
        active: "f17",
    },
    {
        img: gm("shoplogo/20.png"),
        name: "江苏朗旭节能科技有限公司",
        active: "f18",
    },
    {
        img: gm("shoplogo/21.png"),
        name: "金埔园林",
        active: "f19",
    },
    {
        img: gm("shoplogo/22.png"),
        name: "艾列天元建筑技术(江苏)有限公司",
        active: "f20",
    },
    {
        img: gm("shoplogo/23.png"),
        name: "加拿大DV硬木公司",
        active: "f21",
    },
    {
        img: gm("shoplogo/24.png"),
        name: "贝加艾奇（上海）建筑设计咨询有限公司",
        active: "f22",
    },
    {
        img: gm("shoplogo/25.png"),
        name: "湖北欧肯环境科技有限公司",
        active: "f23",
    },
    {
        img: gm("shoplogo/26.png"),
        name: "加拿大凯顿国际公司",
        active: "f24",
    },
    {
        img: gm("shoplogo/27.png"),
        name: "科洛德涂料",
        active: "f25",
    },
    {
        img: gm("shoplogo/28.png"),
        name: "林创中国",
        active: "f26",
    },
    {
        img: gm("shoplogo/29.png"),
        name: "康博达建设科技集团",
        active: "f27",
    },
    {
        img: gm("shoplogo/31.png"),
        name: "江苏碧海安全玻璃科技股份有限公司",
        active: "f29",
    },
    {
        img: gm("shoplogo/32.png"),
        name: "常州天经新型建材有限公司",
        active: "f30",
    },
    {
        img: gm("shoplogo/33.png"),
        name: "万邦德·栋梁铝业有限公司",
        active: "f31",
    },
    {
        img: gm("shoplogo/34.png"),
        name: "江苏苏渥节能科技有限公司",
        active: "f32",
    },
    {
        img: gm("shoplogo/35.jpeg"),
        name: "江阴五岳建筑节能科技有限公司",
        active: "f33",
    },
    {
        img: gm("shoplogo/36.png"),
        name: "江苏捷阳科技股份有限公司",
        active: "f34",
    },
    {
        img: gm("shoplogo/37.png"),
        name: "江苏金百合门窗科技有限公司",
        active: "f35",
    },
    {
        img: gm("shoplogo/38.png"),
        name: "南京君乐建筑新材料有限公司",
        active: "f36",
    },
    {
        img: gm("shoplogo/39.png"),
        name: "江苏克拉赛克绿色建筑产业发展有限公司",
        active: "f37",
    },
    // {
    //     img: gm("shoplogo/40.png"),
    //     name: "南通海鹰木业股份有限公司",
    //     active: "f38",
    // },
    {
        img: gm("shoplogo/41.png"),
        name: "无锡利日能源科技有限公司",
        active: "f39",
    },
    //  {
    //     img: gm("shoplogo/42.png"),
    //     name: "无锡鑫纵横遮阳技术有限公司",
    //     active: "f40",
    // },
    {
        img: gm("shoplogo/43.png"),
        name: "南通鸿盛装饰工程有限公司",
        active: "f41",
    },
    {
        img: gm("shoplogo/44.png"),
        name: "江苏苏鑫装饰（集团）公司",
        active: "f42",
    },
    {
        img: gm("shoplogo/45.png"),
        name: "江苏恒信诺金科技股份有限公司",
        active: "f43",
    },
    {
        img: gm("shoplogo/46.png"),
        name: "江苏洁润管业有限公司",
        active: "f44",
    },
    {
        img: gm("shoplogo/47.jpg"),
        name: "江苏山由帝奥节能新材股份有限公司",
        link: "https://mp.weixin.qq.com/s/fOiNPWGT17qF1_EWwjVwjA"
    },
    {
        name: "江苏城乡建设职业学院",
        active: "f45",
    },
    {
        name: "江苏省规划设计集团有限公司",
        active: "f46",
    },
    {
        img: gm("shoplogo/48.png"),
        name: "德国思帕",
        active: "f47",
    },
    {
        img: gm("shoplogo/49.png"),
        name: "苏州市建筑科学研究院集团股份有限公司",
        active: "f48",
    },
    {
        img: gm("shoplogo/50.jpeg"),
        name: "苏州新一造价师价格事务所有限公司",
        active: "f49",
    },
    {
        img: gm("shoplogo/51.png"),
        name: "苏州园林设计院股份有限公司",
        active: "f50",
    },
    {
        img: gm("shoplogo/52.jpg"),
        name: "泰山玻璃纤维有限公司",
        active: "f51",
    },
    {
        img: gm("shoplogo/53.png"),
        name: "南京天加环境科技有限公司",
        active: "f52",
    },
    {
        img: gm("shoplogo/54.png"),
        name: "江苏同盛环保技术有限公司",
        active: "f53",
    },
    {
        img: gm("shoplogo/55.png"),
        name: "五行诺道",
        active: "f54",
    },
    {
        img: gm("shoplogo/56.png"),
        name: "新华盛节能科技股份有限公司",
        active: "f55",
    },
    {
        img: gm("shoplogo/57.png"),
        name: "江苏新盈装配建筑科技有限公司",
        active: "f56",
    },
    {
        img: gm("shoplogo/58.png"),
        name: "徐州工程机械集团有限公司",
        active: "f57",
    },
    {
        img: gm("shoplogo/59.png"),
        name: "江苏扬建集团有限公司",
        active: "f58",
    },
    {
        img: gm("shoplogo/60.png"),
        name: "英达科技集团",
        active: "f59",
    },
    {
        img: gm("shoplogo/61.png"),
        name: "永臻科技股份有限公司",
        active: "f60",
    },
    {
        img: gm("shoplogo/62.png"),
        name: "远大能源利用管理有限公司",
        active: "f61",
    },
    {
        img: gm("shoplogo/63.png"),
        name: "镇江绿建工程咨询有限公司",
        active: "f62",
    },
    {
        img: gm("shoplogo/64.jpg"),
        name: "中建八局文旅",
        active: "f63",
    },
    {
        img: gm("shoplogo/65.png"),
        name: "中建五局第一建设有限公司",
        active: "f64",
    },
    {
        img: gm("shoplogo/66.png"),
        name: "中铁上海局",
        active: "f65",
    },
    {
        img: gm("shoplogo/67.jpeg"),
        name: "中铁七局集团有限公司",
        active: "f66",
    },
    {
        img: gm("shoplogo/68.jpg"),
        name: "中兴通讯",
        active: "f67",
    },
    {
        img: gm("shoplogo/69.png"),
        name: "江苏筑森建筑设计有限公司",
        active: "f68",
    },
    {
        img: gm("shoplogo/70.png"),
        name: "常州市建筑科学研究院集团股份有限公司",
        active: "f69",
    },
    {
        img: gm("shoplogo/71.png"),
        name: "东南大学建筑设计研究院有限公司",
        active: "f70",
    },
    {
        img: gm("shoplogo/72.png"),
        name: "智泰新能源（东台）有限公司 ",
        active: "f71",
    },
    {
        img: gm("shoplogo/73.png"),
        name: "柯利达 ",
        active: "f72",
    },
    {
        img: gm("shoplogo/74.png"),
        name: "江苏龙腾工程设计股份有限公司 ",
        active: "f73",
    },
    {
        img: gm("shoplogo/75.png"),
        name: "南京金宸建筑设计有限公司 ",
        active: "f74",
    },
    {
        img: gm("shoplogo/76.png"),
        name: "常州亚玛顿股份有限公司 ",
        active: "f75",
    },
    {
        img: gm("shoplogo/77.png"),
        name: "云玄技术(深圳)有限公司 ",
        active: "f76",
    },
    {
        img: gm("shoplogo/8.png"),
        name: "南京长江都市建筑设计股份有限公司",
        active: "f77",
    },
    {
        img: gm("shoplogo/78.png"),
        name: "中建八局第三建设有限公司",
        active: "f78",
    },
    {
        img: gm("shoplogo/79.jpeg"),
        name: "SGS",
        active: "f79",
    },
    {
        img: gm("shoplogo/80.png"),
        name: "南京工业大学",
        active: "f80",
    },
    {
        img: gm("shoplogo/64.jpg"),
        name: "中建二局第三建筑工程有限公司",
        active: "f81",
    },
    {
        img: gm("shoplogo/81.png"),
        name: "苏州美尔邦纳米材料有限公司",
        active: "f82",
    },
    {
        img: gm("shoplogo/82.png"),
        name: "中国建设银行江苏省分行",
        active: "f83",
    },
    {
        img: gm("shoplogo/83.png"),
        name: "南京银行股份有限公司",
        active: "f84",
    },
  
    {
        img: gm("shoplogo/84.jpg"),
        name: "浦发银行",
        active: "f85",
    },
    {
        img: gm("shoplogo/85.jpg"),
        name: "中国人民财产保险股份有限公司江苏省分公司",
        active: "f86",
    },
    {
        img: gm("shoplogo/86.jpg"),
        name: "中国中信集团有限公司",
        active: "f87",
    },
    {
        img: gm("shoplogo/87.png"),
        name: "紫金财产保险股份有限公司",
        active: "f88",
    },
]


export const homeNewList = [
    {
        timer: "07-13",
        title: i18n.t("newIndex.line1"),
        subtitle: i18n.t("newIndex.line2"),
        img: gm("1-2.png"),
        link: "https://mp.weixin.qq.com/s/xPyyWB-iP9J7v0_kMaIYKQ"
    },
    {
        timer: "08-10",
        title: i18n.t("newIndex.line3"),
        subtitle: i18n.t("newIndex.line4"),
        img: gm("1-3.jpg"),
        link: "https://mp.weixin.qq.com/s/snG9ysGf1d9UoZcPGZvAdQ"
    },
    {
        timer: "08-18",
        title: i18n.t("newIndex.line5"),
        subtitle: i18n.t("newIndex.line6"),
        img: gm("1-4.jpg"),
        link: "https://mp.weixin.qq.com/s/sg_m1zSGWARpQcB3yeLcbg"
    },
    {
        timer: "08-30",
        title: i18n.t("newIndex.line7"),
        subtitle: i18n.t("newIndex.line8"),
        img: gm("1-8.png"),
        link: "https://mp.weixin.qq.com/s/fJHVJ85dnFORA35-j99wmg"
    },
    {
        timer: "08-31",
        title: i18n.t("newIndex.line9"),
        subtitle: i18n.t("newIndex.line10"),
        img: gm("1-9.png"),
        link: "https://mp.weixin.qq.com/s/tvcRKnRlnI-bSqSNMRV8dg"
    },
    {
        timer: "09-05",
        title: '【展商风采】让建筑“邂逅”绿色 助力实现“双碳”目标',
        subtitle: '今天介绍的是新华盛节能科技股份有限公司、永臻科技股份有限公司、江苏山由帝奥节能新材股份有限公司、江苏洁润管业有限公司、镇江绿建工程咨询有限公司、北京东方雨虹防水技术股份有限公司、江苏绿瓴数字城市与智能建造研究院有限公司、江苏锦上建筑科技有限公司八家企业，一起来看看吧。\n点击查看详情 >>',
        img: gm("news/3.jpg"),
        link: "https://mp.weixin.qq.com/s/Qd5cxlRUv2kze9dEfJMFLw"
    },

    {
        timer: "09-14",
        title: '【展商风采】解密“绿色”展会背后企业发展“密码”',
        subtitle: '点击查看详情 >>',
        img: gm("news/4.jpg"),
        link: "https://mp.weixin.qq.com/s/WfVcpOJO10L5BKQDWLnquA"
    },


    {
        timer: "09-15",
        title: '关于举办 2023 江苏绿色低碳建筑国际博览会的通知 \n点击查看详情 >>',
        subtitle: '点击查看详情 >>',
        link: "https://mp.weixin.qq.com/s/01d_xM7hjvyPyAcpPJQHzw",
        img: gm("new-1.png"),
    },
    {
        timer: "09-15",
        title: '亮点“剧透” !2023绿色低碳建筑国际博览会精彩抢先看！ \n点击查看详情 >>',
        subtitle: '点击查看详情 >>',
        link: "https://mp.weixin.qq.com/s/_x4WGNht7sNdXUcyQQZ0Sw",
        img: gm("news/1.jpg"),
    },
    {
        timer: "09-20",
        title: '重要通知说两遍！\n点击查看详情 >>',
        subtitle: '点击查看详情 >>',
        link: "https://mp.weixin.qq.com/s/2QrITv-2lB1mDdOUovK5xg",
        img: gm("new-1.png"),
    },
    {
        timer: "09-21",
        title: '2023江苏绿色低碳建筑国际博览会活动报名指南\n点击查看详情 >>',
        subtitle: '点击查看详情 >>',
        link: "https://mp.weixin.qq.com/s/UDIotnkfdPeOuiqmaXfZTw",
        img: gm("news/5.jpg"),
    },
    {
        timer: "09-25",
        title: '“学习强国”平台重磅发布！ 首届江苏绿色低碳建筑国际博览会即将开幕！',
        subtitle: '点击查看详情 >>',
        link: "https://mp.weixin.qq.com/s/eleFRcwMqriW93bUYuBx6Q",
        img: gm("news/6.png"),
    },
    {
        timer: "09-26",
        title: '2023江苏绿色低碳建筑国际博览会将于10月10日开幕!',
        subtitle: '点击查看详情 >>',
        link: "https://mp.weixin.qq.com/s/lDPuQzabNMTEsKUL1yGoOA",
        img: gm("news/7.png"),
    },
    {
        timer: "09-27",
        title: '城市“双碳先导区”首次亮相江苏绿色低碳建筑国际博览会',
        subtitle: '点击查看详情 >>',
        link: "https://mp.weixin.qq.com/s/tx6m21-FWHV99REfL_lKjw",
        img: gm("news/8.png"),
    },
    {
        timer: "09-27",
        title: '【展商风采】勇创新研新品，二十六度推“绿色”促发展',
        subtitle: '点击查看详情 >>',
        link: "https://mp.weixin.qq.com/s/M-PbRrS3mk9RAXgtsir55Q",
        img: gm("news/9.png"),
    },
    {
        timer: "09-27",
        title: '【展商风采】低碳科技打造绿色未来 江苏建筑企业逐“绿”而行',
        subtitle: '点击查看详情 >>',
        link: "https://mp.weixin.qq.com/s/BNcCuXbqhgmT9k5wSfp_tA",
        img: gm("news/10.png"),
    },
    {
        timer: "09-28",
        title: '“绿建”为桥，中加企业博览会续写合作之“谊”',
        subtitle: '点击查看详情 >>',
        link: "https://mp.weixin.qq.com/s/r57Yf-Yk8lN1ms704YpHRg",
        img: gm("news/11.png"),
    },
    {
        timer: "09-29",
        title: '中秋花好月圆 国庆普天同庆',
        subtitle: '点击查看详情 >>',
        link: "https://mp.weixin.qq.com/s/KP-7iuW9eK4lSPdy3_6k7A",
        img: gm("news/12.png"),
    },
 
    {
        timer: "09-29",
        title: '【展商风采】建筑业高质量发展 助力“双碳”排放 打造绿色人居环境',
        subtitle: '点击查看详情 >>',
        link: "https://mp.weixin.qq.com/s/70CqSHEQ5HyVEFWJmfH_LQ",
        img: gm("news/13.png"),
    },
    {
        timer: "09-29",
        title: '【展商风采】英达科技集团亮相博览会让通行更"低碳"',
        subtitle: '点击查看详情 >>',
        link: "https://mp.weixin.qq.com/s/AxQo6l7d79_uaWd3Qp1tbg",
        img: gm("news/14.png"),
    },
    {
        timer: "09-30",
        title: '【展商风采】绿色金融助力建筑业绿色发展',
        subtitle: '点击查看详情 >>',
        link: "https://mp.weixin.qq.com/s/M2HfW3lFjR9RfDXnej4WBA",
        img: gm("news/15.png"),
    },
    {
        timer: "09-30",
        title: '【展商风采】建筑门窗行业践行绿色低碳高质量发展',
        subtitle: '点击查看详情 >>',
        link: "https://mp.weixin.qq.com/s/YGjwNT46uhbJBsAweXDlkg",
        img: gm("news/16.png"),
    },
    {
        timer: "10-01",
        title: '【展商风采】龙头企业探索推进建筑业绿色发展引领示范效应凸显',
        subtitle: '点击查看详情 >>',
        link: "https://mp.weixin.qq.com/s/226zKuK7rCfWv2Ff3_mCnQ",
        img: gm("news/17.png"),
    },
    {
        timer: "10-01",
        title: '【展商风采】探索绿色建筑可持续发展新路径一砖一瓦绿意深深',
        subtitle: '点击查看详情 >>',
        link: "https://mp.weixin.qq.com/s/WY-y8ZyZ05nji_p-z2ZJ1Q",
        img: gm("news/18.png"),
    },
    {
        timer: "10-07",
        title: '【展商风采】绿色建筑发展按下“快进键” 建筑企业探索发展新路径',
        subtitle: '点击查看详情 >>',
        link: "https://mp.weixin.qq.com/s/S8rET7nCAg5YH8ZMNDW6DQ",
        img: gm("news/19.jpg"),
    },
    {
        timer: "10-07",
        title: '2023 江苏绿色低碳建筑国际博览会距开幕还有3天',
        subtitle: '点击查看详情 >>',
        link: "https://mp.weixin.qq.com/s/rmbmUs9CXqRsISJpugDBrA",
        img: gm("news/20.jpg"),
    },
    {
        timer: "10-08",
        title: '倒计时2天！内容提前放送，这份攻略请收好',
        subtitle: '点击查看详情 >>',
        link: "https://mp.weixin.qq.com/s/2uxWIHgB06bvQjYeH8B-2w",
        img: gm("news/21.jpg"),
    },
    {
        timer: "10-09",
        title: '开幕倒计时1天!我们明天见!',
        subtitle: '点击查看详情 >>',
        link: "https://mp.weixin.qq.com/s/Sd-dn2Z2YP6jbmsBvrobWg",
        img: gm("news/22.jpg"),
    },
    {
        timer: "10-10",
        title: '2023江苏绿色低碳建筑国际博览会在南京开幕！',
        subtitle: '点击查看详情 >>',
        link: "https://mp.weixin.qq.com/s/1YZHYaMYKLWi8hRfc1Dp9g",
        img: gm("news/23.jpg"),
    },
    {
        timer: "10-11",
        title: '精彩回顾之一｜2023江苏绿色低碳建筑国际博览会',
        subtitle: '点击查看详情 >>',
        link: "https://mp.weixin.qq.com/s/R8D1XGPJSo1T0txmkO7-ig",
        img: gm("news/24.jpg"),
    },
    {
        timer: "10-12",
        title: '精彩回顾之二｜2023江苏绿色低碳建筑国际博览会',
        subtitle: '点击查看详情 >>',
        link: "https://mp.weixin.qq.com/s/m50Pd05wPkrX-LGttOnE9A",
        img: gm("news/25.jpg"),
    },
    {
        timer: "10-13",
        title: '精彩回顾之三｜2023江苏绿色低碳建筑国际博览会',
        subtitle: '点击查看详情 >>',
        link: "https://mp.weixin.qq.com/s/m50Pd05wPkrX-LGttOnE9A",
        img: gm("news/26.jpg"),
    },
    {
        timer: "10-14",
        title: '听听2023江苏绿色低碳建筑国际博览会参展单位怎么说',
        subtitle: '点击查看详情 >>',
        link: "https://mp.weixin.qq.com/s/Ukk0TNcuKEpxsstPr0zUKg",
        img: gm("news/27.jpg"),
    },
    {
        timer: "10-16",
        title: '精彩回顾之四｜2023江苏绿色低碳建筑国际博览会',
        subtitle: '点击查看详情 >>',
        link: "https://mp.weixin.qq.com/s/k8MJN18v-SDEtDJgzLATmw",
        img: gm("news/28.jpg"),
    },
    {
        timer: "10-18",
        title: '实力刷屏！这场博览会多家主流媒体集中报道',
        subtitle: '点击查看详情 >>',
        link: "https://mp.weixin.qq.com/s/HQTbwH9CHivU4XmHAuiYVw",
        img: gm("news/29.jpg"),
    },
    {
        timer: "10-24",
        title: '精彩回顾之五｜“数”说博览会',
        subtitle: '点击查看详情 >>',
        link: "https://mp.weixin.qq.com/s/D3nYZxbSRIrlFst4jP72Aw",
        img: gm("news/30.jpg"),
    },
]