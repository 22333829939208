import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '@/store'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: ""
    },
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: "登录"
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    meta: {
      title: "注册"
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue')
  },
  {
    path: '/forgetPwd',
    name: 'ForgetPwd',
    meta: {
      title: "找回密码"
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ForgetPwd.vue')
  },
  {
    path: '/about',
    name: 'AboutShow',
    meta: {
      title: "关于"
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutShow.vue')
  },
  {
    path: '/exhibitor',
    name: 'Exhibitor',
    meta: {
      title: "展商聚焦"
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Exhibitor.vue')
  },
  // {
  //   path: '/journalof',
  //   name: 'journalof',
  //   meta: {
  //     title: "电子会刊"
  //   },
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Journalof.vue')
  // },
  // {
  //   path: "/news",
  //   name: 'News',
  //   meta: {
  //     title: "展会新闻"
  //   },
  //   component: () => import(/* webpackChunkName: "about" */ '../views/News.vue')
  // },
  {
    path: "/contactUs",
    name: 'contactUs',
    meta: {
      title: "联系我们"
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactUs.vue')
  },
  {
    path: "/firms",
    name: 'firms',
    meta: {
      title: "展商"
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Firms.vue')
  },
  {
    path: "/maneuver",
    name: 'Maneuver',
    meta: {
      title: "展期活动"
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Maneuver.vue')
  },

  //  移动端目录
  {
    path: '/h5',
    name: 'h5index',
    meta: {
      title: ""
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/h5/index.vue'),
    children: [
      {
        path: '/h5/home',
        name: 'h5home',
        component: () => import(/* webpackChunkName: "about" */ '../views/h5/home.vue'),
      },
      {
        path: '/h5/about',
        name: 'h5home',
        meta: {
          title: "关于"
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/h5/about.vue'),
      },
      {
        path: '/h5/exhibitor',
        name: 'h5exhibitor',
        meta: {
          title: "展商聚焦"
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/h5/exhibitor.vue'),
      },
      {
        path: '/h5/maneuver',
        name: 'h5maneuver',
        meta: {
          title: "展期活动"
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/h5/maneuver.vue'),
      },
      {
        path: '/h5/contactUs',
        name: 'h5contactUs',
        meta: {
          title: "联系我们"
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/h5/contactUs.vue'),
      },
      {
        path: '/h5/login',
        name: 'h5login',
        meta: {
          title: "登录"
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/h5/login.vue'),
      },
      {
        path: '/h5/register',
        name: 'h5register',
        meta: {
          title: "注册"
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/h5/register.vue'),
      },
      {
        path: '/h5/firms',
        name: 'h5firms',
        meta: {
          title: "展商"
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/h5/firms.vue'),
      }
    ]
  },

]
const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function isMobile() {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return flag;
}

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title ? to.meta.title + '-' : ''} 江苏绿色低碳建筑国际博览会`

  if (isMobile()) {
    let toPath = to.path
    if (toPath.includes('/h5')) {
      store.state.isMobileStyle = true
      next()
      return
    }
    if (toPath == '/') {
      next('/h5/home')
      return
    }
    let res = confirm('检测到您是手机用户，是否跳转到手机端？')
    if (res) {
      store.state.isMobileStyle = true
      next('/h5' + toPath)
    } else {
      store.state.isMobileStyle = false
      next()
    }
  } else {
    next()
  }
})

export default router
