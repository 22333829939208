export default {
    nav: {
        index: "首页",
        presentation: "展会介绍",
        service: "展商服务",
        viewers: "观众服务",
        news: "展会新闻",
        contact: "联系我们"
    },
    common: {
        plh: "请输入",
        slp: "请选择",
        exhibition: "展会概况",
        scope: "展示范围",
        themesev: "7大主题，",
        ciogl: "全面诠释绿色低碳",
        highlights: "展会亮点",
        highlightsText: "模XX、覆盖xxx领域",
        learnmore: "了解详情",
        highlightsEvents: "精彩活动",
        atlas: "展示图册",
        exhibiting: "参展名企",
        onsiteMedia: "现场媒体",
        indexTitle: "2023江苏绿色低碳建筑国际博览会将于10月在南京举行",
        indexSubTitle: "本次博览会以“绿色城乡，迈向更低碳的未来”为主题，由江苏省住房和城乡建设厅、中国国际贸易促进委员会江苏省分会共同主办。据江苏省住建厅总工程师路宏伟介绍，博览会将重点展示绿色低碳发展最新成果",
        checkDetail: "查看详情",
        zhanhuiMsg: "展会消息",
        lobbies: "供采大厅",
        lobbiesTitle: "一键对接供采双方需求",
        lobbiesSubTitle: "提交采购意向 -> 推荐供应产品 -> 参展商响应",
        mybuyer: "我是采购商",
        publishing: "发布需求",
        myExhibitor: "我是参展商",
        finding: "寻找商机",
        contactUs: "联系我们",
        contactUs2: "联系方式",
        contactUsSub: "您可以通过以下方式与我们取得联系，欢迎您的来电",
        telephones: "电话",
        mail: "邮箱",
        address: "地址",
        addressText: "南京市建邺区江东中路300号",
        publicNumber: " 公众号",
        publicNumberText: " 扫描二维码更多了解我们",
        outlogin: '退出登录'
    },
    people_server_map_address: {
        line_1: '一、地铁',
        line_2: '地铁2号线或10号线至元通站，4号口出步行5分钟即可到达4~7号展馆。',
        line_3: '南京南站： 乘坐地铁1号线南延线(往迈皋桥方向), 在安德门站下车，转乘地铁10号线(往奥体中心方向),在元通站下车(4号口出右转，沿江东中路走190米，右转进入白龙江西街直走280米到达)，步行至南京国际博览中心‚乘坐地铁3号线(往林场方向)，到达大行宫站，换乘地铁2号线（往油坊桥），在元通站下车(4号口出右转，沿江东中路走180米，右转进入白龙江西街直走280米到达)，步行至南京国际博览中心',
        line_4: '南京站：乘坐地铁1号线(往中国药科大学方向)，到达新街口站，换乘地铁2号线（往油坊桥），在元通站下车(4号口出右转，沿江东中路走180米，右转进入白龙江西街直走280米到达)，步行至南京国际博览中心',

        line_5: '二、公交',
        line_6: '1、江东中路白龙江东街-公交站 途经公交车：102路，109路',
        line_7: '2、江东中路金陵会议中心-公交站 途经公交车：b2路',
        line_8: '3、江东中路博览中心东门-公交站 途经公交车：102路，109路，b2路',
        line_9: '4、燕山路白龙江西街-公交站 途经公交车：102路，134路',
        line_10: '5、江东中路元通南-公交站 途经公交车：102路，109路，b2路',

        line_11: '三、有轨电车',
        line_12: '有轨电车1号线博览中心东门/元通站下',

        line_13: '四、自驾',
        line_14: '（一）到达展馆：',
        line_15: '1、上海方向为沪宁高速-绕城高速-306B出口-江山大街-江东中路/燕山路',
        line_16: '2、马鞍山芜湖方向为宁马高速-绕城高速-306B出口-江山大街-江东中路/燕山路合肥方向为宁合高速-长江三桥-绕城高速-306B出口-江山大街-江东中路/燕山路',
        line_17: '3、扬州方向为宁通高速-长江二桥-绕城高速-306B出口江山大街-江东中路/燕山路',
        line_18: '4、杭州方向为宁杭高速-机场高速-绕城高速-306B出口-江山大街-江东中路/燕山路',
        line_19: '（二）地下停车场：',
        line_20: '1、燕山路-博览中心下12号门进入展馆-根据现场指示前往P1停车场停车；P1停车场可提供1200个车位，同时设有25个大巴车位，临近1、2号展馆及3A登录厅。',
        line_21: '2、江东中路-博览中心7号门进入展馆-根据现场指示前往P2停车场停车（根据展会需求开放）；P2停车场可提供2200个停车位，临近4-9号展馆、6号门登录厅以及壹食馆（10号馆）。',
    },
    about: {
        line_1: "7月10日，江苏省住房和城乡建设厅在南京宣布，“2023江苏绿色低碳建筑国际博览会”将于2023年10月10日至11日在南京国际博览中心举办。",
        line_2: "据悉，本届博览会以“绿色城乡，迈向更低碳的未来”为主题，由江苏省住房和城乡建设厅、中国国际贸易促进委员会江苏省分会共同主办。博览会为期两天，将重点展示国内外绿色低碳建筑、城乡绿色低碳发展实践、智能建造与新型建筑工业化、城市更新、宜居宜业和美乡村等领域的最新技术成果，打造住房城乡建设绿色低碳交流平台，畅通供应商与采购商、国内市场与国际市场交易渠道。"
    },
    peopleServer: {
        tab1: "参观指南",
        tab2: "参观注册",
        tab3: "交通导图",
        tab4: "酒店住宿",
        tab5: "供需住宿",
        username: "姓名",
        flat: "单位",
        phone: "电话",
        datevisit: "参观日期",
        selectDate: "选择日期",
        verification: "验证码",
        submit: "提交",
        count: "内容",
        plh: "请输入内容",
        submitText: "提交留言",
        plhs: {
            username: "请输入姓名",
            flat: "请输入单位",
            phone: "请输入电话",
            datevisit: "请选择参观日期",
            verification: "请输入验证码",
            content: "请输入内容"
        },
        map: {
            title: "南京国际博览中心",
            subtitle: "地址：江苏省南京市建邺区江东中路300号"
        }
    },
    tabs: {
        exhibition: "展会概况",
        exhibitionSchedule: "展会活动",
        previousReview: "上届回顾",
        download: "资料下载",
        formbioa: "参展申请表",
    },
    shopServer: {
        tab1: "参展流程",
        tab2: "展位价格",
        tab3: "展位图",
        tab4: "搭建报馆",
        tab5: "交通路线",
        tab6: "广告宣传",
        tab7: "现场租赁",
        tab8: "酒店住宿",
        tab9: "参展商手册",
    },
    news: {
        tab1: "展会风采",
        tab2: "示范园风采",
    },
    login: {
        homeTitle: "登录/注册",
        l_title: "欢迎登录",
        l_subtitle: "江苏绿色低碳建筑国际博览会平台",
        tab1: "账号登陆",
        tab2: "手机验证码登录",
        form1: "账号",
        form2: "密码",
        form3: "验证码",
        form4: "我已阅读并同意",
        form5: "用户手册",
        form6: "手机号",
        submit: "登录",
        form7: "注册账号",
        form8: "忘记密码",

        form9: "请完整阅读并同意以上协议",
        form10: "请完整阅读并同意用户协议",
        plh1: "请输入账号",
        plh2: "请输入密码",
        plh3: "请输入验证码",
        plh4: "请输入手机号",
        ret: "返回",
        form11: "登录成功"
    },
    reg: {
        title: "注册账号",
        form1: "发送验证码",
        form2: "邮箱",
        form3: "确认密码",
        form4: "注册",
        plh1: "请输入邮箱地址",
        plh2: "请输入密码",
        plh3: "请输入验证码",
        plh4: "请输入正确的手机号",
        plh5: "请再次输入密码",
        plh6: "请输正确的身份证号码",
        sex: "性别",
        idCard: "身份证号",
        form5: "真实姓名",
        form6: "男",
        form7: "女",
        realName: "真实姓名",
        form8: "两次输入的密码不一致",
        form9: "注册成功",
    },
    forget: {
        next: "下一步",
        form1: "重置密码",
    },

    show_server_swiper: {
        swiper_text_1: '汉庭酒店(南京河西国际博览中心店)',
        swiper_text_2: '国际青年会议酒店',
        swiper_text_3: '南京金奥费尔蒙酒店',
        swiper_text_4: '南京新华传媒大酒店',
        swiper_text_5: '南京华泰万丽酒店',
        swiper_text_6: '南京奥体中心亚朵S酒店',
        swiper_text_7: '美居酒店奥体中心',
        swiper_text_8: '珍宝假日饭店(南京奥体中心店)',
        swiper_text_9: '南京国际博览中心酒店',
        swiper_text_10: '宜尚酒店(南京博览中心奥体东地铁站店)',
        swiper_text_11: '全季酒店(南京河西博览中心店)',
        swiper_text_12: '珍宝假日饭店凤台南路店',
    },
    swiper2: {
        line1: "技术配对、产业对接、人才交流",
        line1_1: "全面展示新成果、新成就",
        line2: "50多场同期活动",
        line2_1: "专题洽谈政策宣讲人才交流全覆盖",
        line3: "12000平米展览面积",
        line3_1: "400个标准展位",
        line4: "行业主管部门、行业链路企业、金融机构、",
        line4_1: "先导示范单位、行业协会、研究所、高校等",
        line5: "50家媒体、80多名记者",
        line5_1: "全方位扩大展会知名度和影响力",
    },
    newIndex: {
        line1: "2023江苏绿色低碳建筑国际博览会将于今年10月举办",
        line2: "7月10日上午，江苏省住房和城乡建设厅召开2023江苏绿色低碳建筑国际博览会新闻通气会。省住房城乡建设厅总工程师路宏伟作情况介绍，中国国际贸易促进委员会江苏省分会副会长钱前等同志回答记者提问。",
        line3: "2023江苏绿色低碳建筑国际博览会正式启航！",
        line4:"搭建住房城乡建设领域技术配对、产业对接、人才交流的专业平台，全面展示绿色低碳城乡发展四新成果、建设成就等，推进创新链产业链资金链人才链深度融合。",
        line5:"看完这条最“炫”的绿色低碳建筑国际博览会云探馆，解锁展区新技能",
        line6:"大力发展绿色低碳建筑，是践行绿色发展理念、改善人居环境、建设美丽中国的重要抓手，也是落实“碳达峰碳中和”目标，实现住房和城乡建设高质量发展的重要举措。",
       
        line7:"【展商风采】聚焦绿色可持续建筑 从樊笼中重返自然",
        line8:"今天介绍的是江苏江海杭萧绿色建筑科技有限公司、南京贝唐建筑科技有限公司、苏州佳固士新材料科技有限公司、江苏宜安建设有限公司、宏大建设集团、江苏擎天工业互联网有限公司、上海圣奎塑业有限公司、江苏扬建集团有限公司等八家企业。",
    
        line9:"【展商风采】绿色低碳 科技赋能建筑产业新风向",
        line10:"今天介绍的是江苏省建筑科学研究院有限公司、苏州柯利达装饰股份有限公司、中亿丰控股集团有限公司三家企业。",
    },
    newAbout:{
        
    }
}