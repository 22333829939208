export const gm = (path)=>{

    // https://djy.0l0l.cn/i/image/1-1-2.png
    // return 'https://djy.0l0l.cn/i/image/' + path
    if(process.env.NODE_ENV == 'production'){
        // return 'https://djy.0l0l.cn/i/image/' + path
        return 'http://www.glcbexpo.com/global/images/' + path
    }else {
        return require('@/assets/image/' + path)
    }
}