import Vue from 'vue'
import VueI18n from 'vue-i18n'

import zhCN from './zh-CN'
import en from './en'

Vue.use(VueI18n)


const langObj = {
    zh: zhCN,
    en: en
}


const i18n = new VueI18n({
    locale: 'zh',
    messages: langObj,

})



export default i18n