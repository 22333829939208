<template>
    <div class="header">
        <div class="info">
            <div class="logo">
                <img :src="$gm('logo2.png')" alt="" />
            </div>
            <div class="nav">
                <div class="nav-item" v-for="(v, i) in navList" :key="i">
                    <div class="item-info" :id="nav == i ? 'active' : ''"  @click="selectItem(v)">
                        <span
                            class="title"
                            v-html="v.title"
                           
                        ></span>
                        <template v-if="v.select && v.select.length != 0">
                            <div class="select">
                                <div
                                    class="select-item"
                                    v-for="(vv, ii) in v.select"
                                    :key="ii"
                                    @click="selectList(vv,ii,v.router)"
                                >
                                    <span>{{ vv.subtitle }}</span>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="login" v-if="!isLogin">
                <div @click="toLogin">登录 / 注册</div>
            </div>
            <div v-else class="select">
                <el-dropdown trigger="click" @command="handleCommand">
                    <span class="el-dropdown-link">
                        {{ userInfo?.mobile }}<i
                            class="el-icon-arrow-down el-icon--right"
                        ></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="1">{{ $t('common.outlogin') }}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <!-- <div class="language">
                <div class="language-info">
                    <span
                        @click="changeLang('zh')"
                        class="text"
                        :id="lang == 'zh' ? 'langactive' : ''"
                        >中</span
                    >
                    <span class="sign">|</span>
                    <span
                        @click="changeLang('en')"
                        class="text"
                        :id="lang == 'en' ? 'langactive' : ''"
                        >EN</span
                    >
                </div>
            </div> -->
        </div>
        <div class="sign" style="height: 60px;"></div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            default: "0",
        },
    },
    data() {
        return {
            navList: [
                {
                    title: "首 &nbsp; 页",
                    router: "/",
                },
                {
                    title: "关于展会",
                    router: "/about",
                    select: [
                        { subtitle: "展会介绍" },
                        { subtitle: "展馆介绍" },
                        { subtitle: "交通指引" },
                        // { subtitle: "注册观展" },
                    ],
                },
                {
                    title: "展商聚焦",
                    router: "/exhibitor",
                    select: [
                        { subtitle: "展商介绍" },
                        { subtitle: "展商手册" },
                        { subtitle: "快速指南" },
                        { subtitle: "电子会刊" },
                        { subtitle: "展商服务" },
                        
                        // { subtitle: "观展预登记" },
                    ],
                },
                // {
                //     title: "电子会刊",
                //     router:"/journalof",
                //     select: [
                //         { subtitle: "零碳观展" },
                //         { subtitle: "低碳动态" },
                //         { subtitle: "活动要闻" },
                //         { subtitle: "参展报名" },
                //     ],
                // },
                {
                    title: "展期活动",
                    router:"/maneuver",
                    select: [
                        { subtitle: "活动报名" },
                        { subtitle: "展期活动一览表" },
                        // { subtitle: "参会手册" },
                        // { subtitle: "零碳观展" },
                        
                    ],
                },
                {
                    title: "联系我们",
                    router:'/contactUs',
                },
            ],
            lang: "zh",
            nav: 0,
            isLogin: false,
            userInfo:{}
        };
    },
    created() {
        if (this.value) {
            this.nav = this.value;
        }
        let i18nlang = sessionStorage.getItem("i18n");
        if (i18nlang == "en" || i18nlang == "zh") {
            this.$i18n.locale = i18nlang;
            this.lang = i18nlang;
        }
        let userInfo = sessionStorage.getItem("userInfo");
        if (userInfo) {
            this.isLogin = true;
            try {
                this.userInfo = JSON.parse(userInfo);
            } catch (error) {
                this.isLogin = false;
            }
        } else {
            this.isLogin = false;
        }
    },
    methods: {
        handleCommand(command){
            switch (command) {
                case '1':
                    this.outLogin()
                    break;
                default:
                    break;
            }
        },
        outLogin(){
            sessionStorage.clear()
            location.reload();
        },
        toLogin() {
            this.$router.push("/login");
        },
        changeLang(type) {
            this.$i18n.locale = type;
            sessionStorage.setItem("i18n", type);
            this.lang = type;
            location.reload();
        },
        selectItem(val) {
            if(val.router != this.$route.path){
                this.$router.push(val.router);
                window.scrollTo(0,0)
            }
        },
        selectList(vv,ii,router){
            // if( router == '/exhibitor' && ii == 4 ){
            //     window.open("http://qybm.glcbexpo.com");
            //     return
            // }

            if(router != this.$route.path){
                this.$router.push({
                    path:router,
                    query:{
                        index:ii
                    }
                });
            }
            this.$emit('select',{
                val:vv,
                index:ii
            })
        }
    },
};
</script>

<style scoped lang="less">
.logo {
    height: 60px;
    box-sizing: border-box;
    // padding: 5px 0;
    img {
        height: 60px;
    }
}
.info {
    // width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    position: fixed;
    left: 0;
    background: #fff;
    right: 0;
    z-index: 1999;
    user-select: none;
}

.nav {
    display: flex;
    padding-left: 60px;
    .item-info {
        height: 100%;
        line-height: 60px;
        padding: 0 15px;
        cursor: pointer;
        position: relative;
        .title {
            font-weight: bold;
        }
    }
    #active {
        background: #11a980;
        .title {
            color: #fff;
        }
    }
    .item-info:hover {
        background: #11a980;

        .title {
            color: #fff;
        }
        .select {
            display: block;
        }
    }
    .select {
        position: absolute;
        left: 0;
        z-index: 99999;
        background: #eeeeee;
        // border-radius: 8px;
        display: none;
        .select-item {
            line-height: normal;
            text-align: left;
            padding: 10px 0;
            padding-left: 10px;
            min-width: 150px;
            color: #11a980;
        }
        .select-item:hover {
            background: #11a980;
            color: #fff;
        }
    }
}
.login {
    display: grid;
    place-items: center;
    margin-left: 30px;

    div {
        color: #11a980;
        font-weight: bold;
        border: 1px solid #11a980;
        padding: 8px 15px;
        cursor: pointer;
    }
}
.language {
    display: flex;
    align-items: center;
    line-height: 34px;
    padding-left: 15px;
    margin-left: 20px;
    .sign {
        padding: 0 8px;
    }
    span {
        cursor: pointer;
    }
    .text {
        font-weight: bold;
    }
    #langactive {
        color: #11a980;
    }
}
.select {
    display: grid; place-items: center;
    // margin-left: 10px;
    line-height: 34px;
    .el-dropdown-link {
        cursor: pointer;
        color: #4ba1af;
    }
}
</style>